.bg-header-img {
    background-image: url("/public/vendor/unsplash/img/portfolio-header-background-1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: background-image 0.25s linear, filter 0.25s linear, height 1s ease-in-out;
    top: 0;
}

.blur{
    filter: blur(16px);
}

.bg-header-img-1 {
    background-image: url("/public/vendor/unsplash/img/portfolio-header-background-1.webp");
}
.bg-header-img-2 {
    background-image: url("/public/vendor/unsplash/img/portfolio-header-background-2.webp");
}
.bg-header-img-3 {
    background-image: url("/public/vendor/unsplash/img/portfolio-header-background-3.webp");
}

.z-index-100{
    z-index: 100;
}

.header-container {
    position: relative;
    width: auto;
    margin-bottom: 0;
    display: flex;
    height: auto;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    margin-top: 20vh;
}

.header-text {
    text-shadow: 4px 4px 2px #343a40;
    text-align: center;
    font-weight: 900;
    margin-bottom: 0;
    width: 70%;
    word-wrap: break-word;
    height: auto !important;
    overflow-wrap: normal;
    font-family: Consolas, monaco, monospace;
}

.language-text-font {
    text-shadow: 4px 4px 2px #343a40;
    text-align: center;
    font-weight: 900;
    margin-bottom: 0;
    word-wrap: break-word;
    height: auto !important;
    overflow-wrap: normal;
    font-family: Consolas, monaco, monospace;
    cursor: pointer;
    font-size: 1.25rem;
}

.header-text-font-size {
    font-size: 1.7rem;
    height: 2.4rem;
}
.header-scroll-down {
    font-size: 0.425rem;
    height: 0.6rem;
}

.hide-on-scroll{
    transition: opacity 0.8s;
    opacity: 0.5;
}
.hide-on-scroll:not(.opacity-0):hover{
    cursor: pointer;
}
.hide-on-scroll.opacity-0{
    opacity: 0;
}

/*SM*/
@media (min-width: 576px) {
    .header-text-font-size {
        font-size: 2.4rem;
        height: 4.8rem;
    }
    .header-scroll-down {
        font-size: 0.6rem;
        height: 1.2rem;
    }
}
/*MD*/
@media (min-width: 768px) {
    .header-text-font-size{
        font-size: 2rem;
        height: 7.2rem;
    }
    .header-scroll-down {
        font-size: 0.5rem;
        height: 1.8rem;
    }
}
/*LG*/
@media (min-width: 992px) {
    .header-text-font-size{
        font-size: 4rem;
        height: 9.6rem;
    }
    .header-scroll-down {
        font-size: 1rem;
        height: 2.4rem;
    }
}
/*XL*/
@media (min-width: 1200px) {
    .header-container {
        margin-top: 40vh;
    }
    .header-text-font-size{
        font-size: 5rem;
        height: 12rem;
    }
    .header-scroll-down {
        font-size: 1.25rem;
        height: 3rem;
    }
}
