


.footer-item-text{
    font-size: 10px;
}

@media (min-width: 360px){
    .fa-xs-4x{
        font-size: 4em;
    }
}

@media (min-width: 576px){
    .fa-sm-4x{
        font-size: 4em;
    }

    .footer-item-text{
        font-size: 16px;
    }
}

@media (min-width: 768px){
    .fa-md-4x{
        font-size: 4em;
    }
}

@media (min-width: 992px){
    .fa-lg-4x{
        font-size: 4em;
    }
}

@media (min-width: 1200px) {
    .fa-xl-4x{
        font-size: 4em;
    }
}