.z-index-2 {
    z-index: 2 !important;
}

.navbar-brand{
    font-size: 20px !important;
    font-weight: 900;
}

.nav-link{
    font-size: 9px !important;
}

.faded-in{
    transform: translate(0, 0);
    opacity: 1.0;
}

.nav-bg-gradient{
    background: linear-gradient(#343a40FF, #343a401F) !important;
}

.trans-bg-250ms{
    transition: background 0.25s linear;
}

.blur{
    filter: blur(16px);
}

/*BETWEEN XS AND SM*/
@media(min-width: 360px){
    .nav-link{
        font-size: 12px !important;
    }
    .navbar-brand{
        font-size: 14px !important;
    }
}

/*SM*/
@media(min-width: 576px){
    .nav-link{
        font-size: 14px !important;
    }
    .navbar-brand{
        font-size: 16px !important;
    }
}

/*MD*/
@media(min-width: 768px){
    .nav-link{
        font-size: 16px !important;
    }
    .navbar-brand{
        font-size: 18px !important;
    }
    .footer-item-text{
        font-size: 16px;
    }
}

/*LG*/
@media(min-width: 992px){
    .nav-link{
        font-size: 16px !important;
    }
    .navbar-brand{
        font-size: 20px !important;
    }
}

/*XL*/
@media (min-width: 1200px){
    .nav-link{
        font-size: 18px !important;
    }
    .navbar-brand{
        font-size: 24px !important;
    }
}