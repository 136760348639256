@media(min-width: 992px){
    .body-scrollbar{
        width: 1em;
        height: 25%;
        position: fixed;
        right: 0;
        left: auto;
        z-index: 100;
        border-radius: 10px;
        transition: background-color 1.5s linear, height 0.25s linear;
        background-color: var(--gray);
    }
    
    .body-scrollbar:hover{
        transition: background-color 0.125s linear;
        background-color: var(--gray);
    }

    .body-scrollbar:focus{
        transition: background-color 0.125s linear;
        background-color: var(--gray);
    }

    .body-scrollbar:active{
        transition: background-color 0.125s linear;
        background-color: var(--gray);
    }
    .body-scrollbar.touch-hover{
        transition: background-color 0.125s linear;
        background-color: var(--gray);
    }
}