.modal-body-image-container {
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
}

@media (min-width: 576px){
    .modal-body-image-container {
        height: 35vh;
    }
}

@media (min-width: 768px){
    .modal-body-image-container {
        height: 45vh;
    }
}
