@font-face {
    font-family: 'Pacifico';
    font-style: normal;
    font-weight: 400;
    src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
    font-display: swap;
}
html{
    overflow-x: visible;
    width: 100%;
    -ms-overflow-style: none;
}
@-moz-document url-prefix() {
    html, body {
        scrollbar-width: none;
    }
}
body{
    width: 100%;
    overflow: auto;
}
body::-webkit-scrollbar {
    width: 0;
    background: var(--primary);
}
body::-webkit-scrollbar-thumb {
    background-color: var(--gray-dark);
}

.bg-transparent{
    background-color: #0000;
}

.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}
.z-index-1 {
    z-index: 1;
}

.vh-50 {
    height: 50vh;
}

.vh-100 {
    height: 100vh;
}

img{
    object-fit: cover;
}

.text-default-case{
    text-transform: none !important;
}

.w-10 {
    width: 10%;
}
.w-50 {
    width: 50%;
}

.fade-in{
    transform: translate(0, 0);
    opacity: 0.0;
    transition: transform 0.5s ease-in-out, opacity 0.5s linear;
}

.img-contain{
    object-fit: contain;
}

/*SM*/
@media (min-width: 576px) {
}
/*MD*/
@media (min-width: 768px) {
}
/*LG*/
@media (min-width: 992px) {
}
/*XL*/
@media (min-width: 1200px) {
    .vh-xl-100{
        height: 100vh;
    }
}

.text-decoration-underline {
    text-decoration: underline !important;
}


@media (min-width: 1400px) {
    .col-xxl {
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      max-width: 100%;
    }
    .row-cols-xxl-1 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .row-cols-xxl-2 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .row-cols-xxl-3 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 33.3333333333%;
              flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .row-cols-xxl-4 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .row-cols-xxl-5 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%;
    }
    .row-cols-xxl-6 > * {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 16.6666666667%;
              flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-xxl-auto {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    .col-xxl-1 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 8.3333333333%;
              flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }
    .col-xxl-2 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 16.6666666667%;
              flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
    }
    .col-xxl-3 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xxl-4 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 33.3333333333%;
              flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-xxl-5 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 41.6666666667%;
              flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%;
    }
    .col-xxl-6 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xxl-7 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 58.3333333333%;
              flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%;
    }
    .col-xxl-8 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 66.6666666667%;
              flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }
    .col-xxl-9 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 75%;
              flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xxl-10 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 83.3333333333%;
              flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%;
    }
    .col-xxl-11 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 91.6666666667%;
              flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }
    .col-xxl-12 {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xxl-first {
      -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
              order: -1;
    }
    .order-xxl-last {
      -webkit-box-ordinal-group: 14;
          -ms-flex-order: 13;
              order: 13;
    }
    .order-xxl-0 {
      -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
              order: 0;
    }
    .order-xxl-1 {
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1;
    }
    .order-xxl-2 {
      -webkit-box-ordinal-group: 3;
          -ms-flex-order: 2;
              order: 2;
    }
    .order-xxl-3 {
      -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
              order: 3;
    }
    .order-xxl-4 {
      -webkit-box-ordinal-group: 5;
          -ms-flex-order: 4;
              order: 4;
    }
    .order-xxl-5 {
      -webkit-box-ordinal-group: 6;
          -ms-flex-order: 5;
              order: 5;
    }
    .order-xxl-6 {
      -webkit-box-ordinal-group: 7;
          -ms-flex-order: 6;
              order: 6;
    }
    .order-xxl-7 {
      -webkit-box-ordinal-group: 8;
          -ms-flex-order: 7;
              order: 7;
    }
    .order-xxl-8 {
      -webkit-box-ordinal-group: 9;
          -ms-flex-order: 8;
              order: 8;
    }
    .order-xxl-9 {
      -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
              order: 9;
    }
    .order-xxl-10 {
      -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
              order: 10;
    }
    .order-xxl-11 {
      -webkit-box-ordinal-group: 12;
          -ms-flex-order: 11;
              order: 11;
    }
    .order-xxl-12 {
      -webkit-box-ordinal-group: 13;
          -ms-flex-order: 12;
              order: 12;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.3333333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.6666666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.3333333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.6666666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.3333333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.6666666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.3333333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.6666666667%;
    }
}
